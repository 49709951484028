export const contractAddresses = {

  weth: {
    1555:'0xE8Bdf8d0CD100caddCC729D5f77fb4e5C6Cf933a',
    1206: '0x0aa0C9E19117483d0Aa3bd4Ef3E4Bf5AaC121BD8',
    7923: '0x5e673659697BED190651218fecd0AfD68CFf768a',
   
  }
}

const multicall = {
  1555: '0xEF116D908B53bD65f47D5e35F3d24c74C89F37C9',
  1206: '0x237Cd3f0241979552C52d9C61cE3c9d7a2B72eA7', //multicall address
  7923: '0x5e673659697BED190651218fecd0AfD68CFf768a',
 
}
const chainId = 1555

interface AddressProps {
  1555: string // Address of the contract
}
export const getAddress = (obj: AddressProps) => {
  return obj[chainId] ? obj[chainId] : obj
}

export const getMulticallAddress = () => {
  return multicall[chainId]
}

export const getPipiAddress = () => {
  return getAddress(contractAddresses.weth)
}
