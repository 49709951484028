import styled from 'styled-components'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const ColumnCenter = styled(Column)`
 dis width: 100%;
  align-items: center;

  
`
export const IconWrapper = styled(Column)`
 display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
      font-size: 1rem;
  background-color: #ffc107;
  border-radius: 5px;
  margin: 10px auto
  
`
export const AutoColumn = styled.div<{
  gap?: 'sm' | 'md' | 'lg' | string
  justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}>`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap }) => (gap === 'sm' && '8px') || (gap === 'md' && '12px') || (gap === 'lg' && '24px') || gap};
  justify-items: ${({ justify }) => justify && justify};
  // margin-bottom:1rem;
  // margin-top:1rem;
  
`

export default Column
